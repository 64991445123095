<template>
  <div class="content">
    <h1>{{ msg }}</h1>
    <!-- <img src="../../media/0001.jpg" alt="ljubav" width="500" /> -->

    <b-image
      :src="require('../../media/0001.jpg')"
      alt="logo"
      :responsive="true"
      :rounded="rounded"
    ></b-image>

    <div class="trailer">
      <p>
        2021 ©
        <a href="mailto:info@ma361.de" margin-top="40px">ma361</a>
      </p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";

Vue.use(Buefy);

export default {
  name: "Default",
  props: {
    msg: String
  },
  data() {
    return {
      rounded: true
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  font-family: "Font", Helvetica, Arial;
  font-size: 4vw;
}
.trailer {
  font-family: "Font", Helvetica, Arial;
  font-size: 4vw;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
