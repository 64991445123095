<template>
  <div id="app">
    <Default msg="Under construction... ♥" />
  </div>
</template>

<script>
import Default from "./components/Default.vue";

export default {
  name: "App",
  components: {
    Default
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
@font-face {
  font-family: "Font";
  src: local("Font"),
    url("../src/fonts/ArchitectsDaughter-Regular.ttf") format("truetype");
  font-family: "Font2";
  src: local("Font2"),
    url("../src/fonts/GANG-BANG-CRIME.ttf") format("truetype");
}
</style>
